import React from "react";
import { useOptionContext } from "../App";

export const Header = (props) => {
  const { selectedOption } = useOptionContext();

  return (
    <header id="header">
      <div
        className="intro"
        style={{
          background: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${
            selectedOption === "Gates"
              ? `../img/introGateBG.jpg`
              : `../img/introGarageBG.jpg`
          }) center center no-repeat`,
        }}
      >
        <div className="overlay">
          <div className="container">
            <div className="row">
              <div className="col-md-8 col-md-offset-2 intro-text">
                <h1>
                  {props.data ? props.data.title : "Loading"}
                  <span></span>
                </h1>
                <p>
                  {props.data
                    ? selectedOption === "Gates"
                      ? props.data.gatesParagraph
                      : props.data.garageParagraph
                    : "Loading"}
                </p>
                <a href="#about" className="btn btn-custom btn-lg page-scroll">
                  Learn More
                </a>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
