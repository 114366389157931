import React from "react";
import { useOptionContext } from "../App";

export const Services = (props) => {
  const { selectedOption } = useOptionContext();

  const data = props.data
    ? selectedOption === "Gates"
      ? props.data.gate
      : props.data.garage
    : "loading";

  return (
    <div id="services" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Our Services</h2>
          <p>
            We do all kinds of{" "}
            {selectedOption === "Gates" ? "gate" : "garage door"} repairs and
            replacements. Prices vary by service and product. All of our
            services are covered by warranty. Please contact us for more
            details.
          </p>
        </div>
        <div className="row">
          {data !== "loading" &&
            data.map((d, i) => (
              <div
                key={`${d.name}-${i}`}
                className={selectedOption === "Gates" ? "col-md-6" : "col-md-4"}
              >
                {" "}
                <img
                  src={d.image}
                  alt="_image"
                  style={{ borderRadius: "50%", border: "2px solid black" }}
                />
                <div className="service-desc">
                  <h3>{d.name}</h3>
                  <p>{d.text}</p>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};
