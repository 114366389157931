import React, { createContext, useState, useContext, useEffect } from "react";
import { Navigation } from "./components/navigation";
import { Header } from "./components/header";
import { About } from "./components/about";
import { Services } from "./components/services";
import { Gallery } from "./components/gallery";
import { Testimonials } from "./components/testimonials";
import { Contact } from "./components/contact";
import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import "./App.css";
import Landing from "./components/landing";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 500,
  speedAsDuration: true,
});

const OptionContext = createContext();

export const useOptionContext = () => useContext(OptionContext);

const App = () => {
  const [landingPageData, setLandingPageData] = useState({});
  const [selectedOption, setSelectedOption] = useState(null);

  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
  };

  return (
    <div>
      {selectedOption ? (
        <>
          <OptionContext.Provider
            value={{ selectedOption, handleOptionSelect }}
          >
            <Navigation />
            <Header data={landingPageData.Header} />
            <About data={landingPageData.About} />
            <Services data={landingPageData.Services} />
            <Gallery data={landingPageData.Gallery} />
            <Testimonials data={landingPageData.Testimonials} />
            <Contact data={landingPageData.Contact} />
          </OptionContext.Provider>
        </>
      ) : (
        <Landing onSelect={handleOptionSelect} />
      )}
    </div>
  );
};

export default App;
