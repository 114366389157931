import React from "react";
import { useOptionContext } from "../App";

export const Navigation = () => {
  const styles = {
    navChoice: { color: "white" },
    logoStyle: { cursor: "pointer" },
  };
  const { handleOptionSelect } = useOptionContext();

  return (
    <nav
      style={{ backgroundColor: "black" }}
      id="menu"
      className="navbar navbar-default navbar-fixed-top"
    >
      <div className="container">
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
          >
            {" "}
            <span className="sr-only">Toggle navigation</span>{" "}
            <span
              style={{ backgroundColor: "white" }}
              className="icon-bar"
            ></span>{" "}
            <span
              style={{ backgroundColor: "white" }}
              className="icon-bar"
            ></span>{" "}
            <span
              style={{ backgroundColor: "white" }}
              className="icon-bar"
            ></span>{" "}
          </button>
          <img
            onClick={() => handleOptionSelect("")}
            src="img/logo.png"
            alt="logo"
            style={styles.logoStyle}
          />
        </div>

        <div
          className="collapse navbar-collapse"
          id="bs-example-navbar-collapse-1"
        >
          <ul className="nav navbar-nav navbar-right">
            <li>
              <a style={styles.navChoice} href="#about" className="page-scroll">
                About
              </a>
            </li>
            <li>
              <a
                style={styles.navChoice}
                href="#services"
                className="page-scroll"
              >
                Services
              </a>
            </li>
            <li>
              <a
                style={styles.navChoice}
                href="#portfolio"
                className="page-scroll"
              >
                Gallery
              </a>
            </li>
            <li>
              <a
                style={styles.navChoice}
                href="#testimonials"
                className="page-scroll"
              >
                Testimonials
              </a>
            </li>
            <li>
              <a
                style={styles.navChoice}
                href="#contact"
                className="page-scroll"
              >
                Contact
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};
