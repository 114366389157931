import React, { useState } from "react";
import { useForm, ValidationError } from "@formspree/react";
import ReCAPTCHA from "react-google-recaptcha";

export const Contact = (props) => {
  const [state, handleSubmit] = useForm("mwkdwzyb");

  const [verified, setVerified] = useState(false);

  const handleRecaptchaChange = () => {
    setVerified(true);
  };

  const handleRecaptchaExpired = () => {
    setVerified(false);
  };

  if (state.succeeded) {
    alert("Thank you! We'll get back to you as soon as we can.");
    window.location.reload(false);
  }
  return (
    <div>
      <div id="contact">
        <div className="container">
          <div className="col-md-8">
            <div className="row">
              <div className="section-title">
                <h2>Get In Touch</h2>
                <p>
                  Please fill out the form below and we will get back to you as
                  soon as possible.
                </p>
              </div>
              <form
                id="contactForm"
                name="sentMessage"
                validate
                onSubmit={handleSubmit}
              >
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        id="name"
                        name="name"
                        className="form-control"
                        placeholder="Name"
                        required
                      />
                      <ValidationError
                        prefix="Email"
                        field="email"
                        errors={state.errors}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="tel"
                        id="phone"
                        name="phone"
                        maxLength={10}
                        className="form-control"
                        placeholder="Phone Number"
                        required
                      />
                      <ValidationError
                        prefix="Phone"
                        field="phone"
                        errors={state.errors}
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <textarea
                    name="message"
                    id="message"
                    className="form-control"
                    rows="4"
                    placeholder="Message"
                    required
                  ></textarea>
                  <ValidationError
                    prefix="Message"
                    field="message"
                    errors={state.errors}
                  />
                </div>
                <ReCAPTCHA
                  sitekey="6Lf1MYEoAAAAAJKWEGsy5hQT0MTmurk0XjxOEnr3"
                  onChange={handleRecaptchaChange}
                  onExpired={handleRecaptchaExpired}
                />
                <br />
                <button
                  disabled={state.submitting || !verified}
                  type="submit"
                  className="btn btn-custom btn-lg"
                >
                  Submit
                </button>
              </form>
            </div>
          </div>
          <div className="col-md-3 col-md-offset-1 contact-info">
            <div className="contact-item">
              <h3>Contact Info</h3>
              <p>
                <span>
                  <i className="fa fa-map-marker"></i> Address
                </span>
                {props.data ? props.data.address : "loading"}
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-phone"></i> Phone
                </span>{" "}
                {props.data ? props.data.phone : "loading"}
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-envelope-o"></i> Email
                </span>{" "}
                {props.data ? props.data.email : "loading"}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div id="footer">
        <div className="container text-center">
          <p>
            &copy; 2023 All Cities Overhead Door. All Rights Reserved. Design by
            TemplateWire. Contractor State License #849672
          </p>
        </div>
      </div>
    </div>
  );
};
