import React from "react";

const LandingPage = ({ onSelect }) => {
  return (
    <div style={styles.landingPageStyle}>
      <div style={styles.landingContent}>
        <img
          src="img/introLogo.png"
          alt="logo"
          style={{ width: "90vw", maxWidth: "600px" }}
        />
        <h3 style={styles.promptText}>Please select an option:</h3>
        <div style={styles.buttonContainer}>
          <div>
            <img
              src="img/resDoor.jpg"
              alt="Garage Doors"
              style={styles.circleImage}
              onClick={() => onSelect("Garage Doors")}
            />
            <h4 style={styles.imageText}>Garage Doors</h4>
          </div>
          <div>
            <img
              src="img/gates.jpg"
              alt="Gates"
              style={styles.circleImage}
              onClick={() => onSelect("Gates")}
            />
            <h4 style={styles.imageText}>Gates</h4>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;

const styles = {
  landingPageStyle: {
    height: "100%",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "linear-gradient(to right, #6372ff 0%, #5ca9fb 100%)",
  },
  landingContent: {
    textAlign: "center",
    color: "#ffffff",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: "20px",
  },
  circleImage: {
    width: "40vw",
    maxWidth: "250px",
    borderRadius: "50%",
    margin: "0 20px",
    cursor: "pointer",
    transition: "transform 0.3s ease",
  },
  promptText: { color: "white" },
  imageText: { color: "white" },
};
